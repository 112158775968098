import { useRouter } from 'next/router';
import { useEffect } from 'react';

const useObservePrevRoute = () => {
  const router = useRouter();

  useEffect(() => {
    // Store the current route in sessionStorage before navigating away
    const handleRouteChange = () => {
      sessionStorage.setItem('previousRoute', router.asPath);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  // Clear the popupShown flag on initial render
  useEffect(() => {
    sessionStorage.setItem('popupShown', 'false');
  }, []);
};

export default useObservePrevRoute;
