export const walletConnectSupportList = [
  'ETH', // Ethereum Mainnet
  'OETH', // Optimistic Ethereum
  'META', // Metadium Mainnet
  'UBQ', // Ubiq
  'UBQ', // Ubiq Network Testnet
  'DIODE', // Diode Prenet
  'MIX', // Mix
  'ELA', // ELA-ETH-Sidechain Mainnet
  'ELA', // ELA-DID-Sidechain Mainnet
  'CRO', // Cronos Mainnet Beta
  'SHIBA', // ShibaChain
  'RSK', // RSK Mainnet
  'GDD', // GoodData Mainnet
  'TBWG', // TBWG Chain
  'VAL', // Valorbit
  'TLOS', // Telos EVM Mainnet
  'XDC', // XinFin Network Mainnet
  'CET', // CoinEx Smart Chain Mainnet
  'ZYX', // Zyx Mainnet
  'BSC', // Binance Smart Chain Mainnet
  'EOS', // EOS Mainnet
  'GO', // GoChain
  'ETC', // Ethereum Classic Mainnet
  'ELLA', // Ellaism
  'EXP', // Expanse Network
  'OKT', // OKExChain Mainnet
  'SOTE', // SoterOne Mainnet
  'PRIM', // PrimusChain mainnet
  'GENE', // GeneChain
  'MTR', // Meter Mainnet
  'GT', // GateChain Mainnet
  'TOMO', // TomoChain
  'XDAI', // xDAI Chain
  'ETI', // EtherInc
  'VLX', // Velas EVM Mainnet
  'TT', // ThunderCore Mainnet
  'ETL', // EtherLite Chain
  'FUSE', // Fuse Mainnet
  'DWEB', // Decentralized Web Mainnet
  'HECO', // Huobi ECO Chain Mainnet
  'MATIC', // Polygon Mainnet
  'POL', // Polygon Mainnet
  'DAX', // DAX CHAIN
  'PHT', // Lightstreams Mainnet
  'SEELE', // Seele Mainnet
  'BTTC', // BitTorrent Chain Mainnet
  'EWT', // Energy Web Chain
  'FTM', // Fantom Opera
  'SUR', // SUR Blockchain Network
  'HPB', // High Performance Blockchain
  'BOBA', // Boba Network
  'KCC', // KCC Mainnet
  'THETA', // Theta Mainnet
  'PLS', // PulseChain Mainnet
  'LIS', // Lisinski
  'RUPX', // Rupaya
  'KAR', // Karura Network
  'ACA', // Acala Network
  'HAIC', // Haic
  'CLO', // Callisto Mainnet
  'AMB', // Ambros Chain Mainnet
  'NEBL', // Nepal Blockchain Network
  'LKN', // Lucky Network
  'NEW', // Newton
  'MATH', // MathChain
  'POPC', // Popcateum Mainnet
  'HALO', // HALO Mainnet
  'CATE', // Catecoin Chain Mainnet
  'ATH', // Atheios
  'BTA', // Btachain
  'TSF', // Teslafunds
  'EGEM', // EtherGem
  'EDG', // Edgeware Mainnet
  'ECO', // Ecoball Mainnet
  'KTO', // Kortho Mainnet
  'IOTX', // IoTeX Network Mainnet
  'ES', // EraSwap Mainnet
  'RBD', // Wegochain Rubidium Mainnet
  'TOOL', // TOOL Global Mainnet
  'BBG', // bloxberg
  'BCH', // Smart Bitcoin Cash
  'BCG', // Blockchain Genesis Mainnet
  'META', // MetaDot Mainnet
  'WEB', // Webchain
  'MINTME', // MintMe.com Coin
  'ESN', // Ethersocial Network
  'FSN', // Fusion Mainnet
  'NRG', // Energi Mainnet
  'PEG', // pegglecoin
  'ARB1', // Arbitrum One
  'AVAX', // Avalanche Mainnet
  'TKM0', // Thinkium Mainnet Chain 0
  'TKM1', // Thinkium Mainnet Chain 1
  'TKM2', // Thinkium Mainnet Chain 2
  'TKM103', // Thinkium Mainnet Chain 103
  'QKC', // QuarkChain Mainnet Root
  'QKC0', // QuarkChain Mainnet Shard 0
  'QKC1', // QuarkChain Mainnet Shard 1
  'QKC2', // QuarkChain Mainnet Shard 2
  'QKC3', // QuarkChain Mainnet Shard 3
  'QKC4', // QuarkChain Mainnet Shard 4
  'QKC5', // QuarkChain Mainnet Shard 5
  'QKC6', // QuarkChain Mainnet Shard 6
  'QKC7', // QuarkChain Mainnet Shard 7
  'AKA', // Akroma
  'POLIS', // Polis Mainnet
  'ELV', // Eluvio Content Fabric
  'ETHO', // Etho Protocol
  'XERO', // Xerom
  'MUSIC', // Musicoin
  'ILT', // IOLite
  'QKC', // quarkblockchain
  'AUX', // Auxilium Network Mainnet
  'JOYS', // Joys Digital Mainnet
  'AQUA', // Aquachain
  'NEON', // Neon EVM MainNet
  'OLT', // OneLedger Mainnet
  'IPOS', // IPOS Network
  'AURORA', // Aurora MainNet
  'ONE0', // Harmony Mainnet Shard 0
  'ONE1', // Harmony Mainnet Shard 1
  'ONE2', // Harmony Mainnet Shard 2
  'ONE3', // Harmony Mainnet Shard 3
  'PIRL', // Pirl
  'PALM', // Palm Mainnet
  'XLM', // Stellar PubNet
  'NEO', // Neo N3 Mainnet
  'FLOW', // Flow Mainnet
  'NEAR', // NEAR Mainnet
  'STX', // Stacks Mainnet
  'ADA', // Cardano Mainnet
  'WAVES', // Waves Mainnet
  'ZKS', // zkSync Era Mainnet
  'EVER', // Everscale Mainnet
  'XRP', // XRPL Livenet
  'MAYA', // Maya Mainnet
  'KOIN', // Koinos Mainnet
  'RUNE', // THORChain Mainnet
  'KUJI', // Kujira Mainnet
  'ALGO', // Algorand Mainnet
  'ELLA', // Ella the heart
  'ZORA', // ZORA Mainnet
  'SANR', // SanR Mainnet
  'HAQQ', // HAQQ Mainnet
  'MEV', // MEVerse Mainnet
  'HOME', // HOME Verse Mainnet
  'LACH', // LaChain Mainnet
  'LINEA', // Linea Mainnet
  'ARBN', // Arbitrum Nova Mainnet
  'SKALE1', // SKALE Europa Hub Mainnet
  'SKALE2', // SKALE Calypso Hub Mainnet
  'SKALE3', // SKALE Nebula Hub Mainnet
  'SKALE4', // SKALE Titan Hub Mainnet
  'CBC', // CryptoBlades Chain
  'HUM', // Human Protocol Chain
  'MNTL', // Mantle Mainnet
  'OASYS', // Oasys Mainnet
  'QI', // QI Mainnet
  'BITCI', // BITCI Mainnet
  'SYS', // Syscoin Mainnet
  'RLX', // Rollux Mainnet
  'DXC', // DX Chain Mainnet
  'CMLK', // Camelark Mainnet
  'ETHW', // EthereumPoW Mainnet
  'XANA', // XANAChain Mainnet
  'DFLY', // Dragonfly Mainnet (Hexapod)
  'HBAR', // Hedera Mainnet
  'REEF', // Reef Mainnet
  'BASE', // Base Mainnet
  'EVMOS', // Evmos Mainnet
  'MATICZK', // Polygon zkEVM Mainnet
  'ADIL', // ADIL Mainnet
  'RON', // Ronin Mainnet
  'BOBABNB', // Boba BNB Mainnet
  'VET', // Vechain Mainnet
  'DLOT', // Dexalot Mainnet
  'MVM', // Mixin Virtual Machine Mainnet
  'CCD', // Concordium Mainnet
  'OPBNB', // opBNB Mainnet
  'DUBX', // DUBX Mainnet
  'ONT', // Ontology Mainnet
  'VELO', // VELO Labs Mainnet
  'WEMIX', // WEMIX3.0 Mainnet
  'DM2', // DM2 Verse Mainnet
  'GMBL', // GMBL Mainnet
  'ULG', // Universal Ledger
  'WAX', // WAX Mainnet
  'AURA', // Aura Network Mainnet
  'EOS', // EOS EVM Mainnet
  'EGLD', // MultiversX Mainnet
  'SCROLL', // Scroll
  'CHZ', // Chiliz Mainnet
  'WAN', // Wanchain Mainnet
  'BXN', // BXN Mainnet
  'SMR', // ShimmerEVM Mainnet
  'MIRAI', // Mirai Chain mainnet
  'CFX', // Conflux eSpace mainnet
  'SUS', // Susono Network
  'HBAR', // Hedera Mainnet
  'CORE', // Core DAO Mainnet
  'RARI', // RARI Mainnet
  'LLP', // LightLink Phoenix Mainnet
  'CSPR', // Casper Network Mainnet
  'ZIL', // Zilliqa Mainnet
  'IMX', // Immutable zkEVM
  'MODE', // Mode Mainnet
  'JOC', // Japan Open Chain Mainnet
  'SOL', // Solana Mainnet
  'AREON', // Areon Network Mainnet
  'SCR', // SecureChain AI Mainnet
  'ASTARZK', // Astar zkEVM Mainnet
  'PHOENIX', // Phoenix Mainnet
  'NUM', // Numbers Mainnet
  'NEURO', // Neurochain Mainnet
  'STRAX', // Stratis Mainnet
  'UNREAL', // unreal
  'REAL', // re.al
  'MINA', // Mina Mainnet
  'MNZ', // MainnetZ Mainnet
  'ERG', // Ergo Mainnet
  'BBIT', // BounceBit Mainnet
  'BITL', // Bitlayer Mainnet
  'ENDUR', // Endurance Mainnet
  'POLYX', // Polymesh Mainnet
  'SAK', // Saakruru Mainnet
  'IOTAEVM', // IotaEVM Mainnet
  'NII', // Nahmii 3 Mainnet
  'OHO', // Oho Mainnet
  'BITL', // Bitlayer Mainnet
  'DOGE', // Dogechain
  'SHIB', // Shibarium
  'BEVM', // BEVM Mainnet
  'DEEL', // Deelance Mainnet
  'ELINK', // Etherlink Mainnet
];
