import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { notEmptyDigitInput } from '@/utils/validation';
import { CodeCountdownTimer } from '@/components/common/CodeCountdownTimer';
import { Checkbox } from '@/components/common/form/Checkbox';
import { Button } from '../buttons';
import { InputDigits } from '../form/InputDigits';
import styles from './Verification.module.css';

export interface VerificationFormData {
  digits: string;
  rememberMe?: boolean;
}

export interface VerificationProps {
  type: 'signIn' | 'signUp';
  phone?: string;
  email?: string;
  confirmValue?: 'Phone' | 'Email';
  className?: string;
  onClick?: () => void;
  onResendPress?: () => void;
  onFormSubmit?: (values: VerificationFormData) => void;
  isLoading?: boolean;
  isCodeInAuthenticator?: boolean;
}

const INITIAL_TIMER_SECONDS = 30;

export const Verification = ({
  type,
  email,
  phone,
  confirmValue,
  className,
  onClick,
  onResendPress,
  onFormSubmit,
  isLoading,
  isCodeInAuthenticator,
}: VerificationProps) => {
  const [code, setCode] = useState<'Phone' | 'Email'>('Phone');
  const [timeLeft, setTimeLeft] = useState(INITIAL_TIMER_SECONDS);

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm<VerificationFormData>({
    resolver: zodResolver(notEmptyDigitInput),
    defaultValues: { digits: '', rememberMe: false },
    mode: 'onTouched',
  });

  function resendSignInCode() {
    onResendPress?.();
    setTimeLeft(INITIAL_TIMER_SECONDS);
  }

  const renderVerification = (type: 'signIn' | 'signUp') => {
    if (type === 'signIn') {
      return (
        <>
          <div className={clsx(styles.form, className)}>
            <div className={styles.formTitleWrapper}>
              <Button
                icon="arrowLeft"
                variants="subtle"
                className={styles.arrowBack}
                onPress={() => onClick && onClick()}
              />
              <h2 className={styles.title}>Verification</h2>
            </div>
            <p className={styles.description}>
              {isCodeInAuthenticator
                ? 'Please, check your authenticator app to retrieve code. '
                : 'We’ve just sent 6-digit code confirmation to phone. '}
              <br />
              Please, enter this code to sign in.
            </p>

            <form onSubmit={onFormSubmit && handleSubmit(onFormSubmit)}>
              <Controller
                name="digits"
                control={control}
                render={({ field }) => <InputDigits {...field} className={styles.inputDigits} />}
              />
              {/* Uncomment below to enable "Remember me" functionality */}

              {/* <Controller
                name="rememberMe"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} value={String(field.value)} className={styles.checkbox} size="large">
                    Remember me
                  </Checkbox>
                )}
              /> */}
              <Button fluid type="submit" isDisabled={!isDirty || !isValid} loading={isLoading}>
                Submit
              </Button>
            </form>
          </div>
          {timeLeft === 0 ? (
            <Button
              variants="subtle"
              size="mediumSubtle"
              icon="retry"
              className={styles.centered}
              onPress={resendSignInCode}
            >
              Resend Code
            </Button>
          ) : (
            <CodeCountdownTimer className={styles.centered} timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
          )}
        </>
      );
    }
    return (
      <div className={clsx(styles.verification, className)}>
        <div className={styles.formTitleWrapper}>
          <h2 className={styles.title}>Confirm your {confirmValue === 'Phone' ? 'Mobile Number' : 'Email'}</h2>
        </div>
        <p className={clsx(styles.description, styles.descriptionLong)}>
          We’ve just sent 6-digit code confirmation to{' '}
          <span className={styles.contact}>{confirmValue === 'Email' ? email : phone}.</span> Please, enter this code to
          continue account creation.
        </p>
        <form onSubmit={onFormSubmit && handleSubmit(onFormSubmit)}>
          <Controller
            name="digits"
            control={control}
            render={({ field }) => <InputDigits {...field} className={styles.inputDigits} />}
          />
          <Button fluid type="submit" isDisabled={!isDirty || !isValid} loading={isLoading}>
            Confirm {confirmValue === 'Email' ? 'Email' : 'Mobile Number'}
          </Button>
          {timeLeft === 0 ? (
            <Button
              variants="subtle"
              size="mediumSubtle"
              icon="retry"
              className={styles.centered}
              onPress={() => {
                setTimeLeft(30);
                if (onResendPress) onResendPress();
              }}
            >
              Resend Code
            </Button>
          ) : (
            <CodeCountdownTimer className={styles.centered} timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
          )}
        </form>
      </div>
    );
  };

  return renderVerification(type);
};
