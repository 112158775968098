import { useGetAddress } from '@/api/queryHooks/useUserController';

const useIsAUSResident = () => {
  const { data: addressData } = useGetAddress({
    refetchOnMount: true,
  });
  return {
    isAUSResident: addressData?.country === 'AUS' || addressData?.country === 'AU',
  };
};

export default useIsAUSResident;
