import classNames from 'classnames';
import clsx from 'clsx';
import { map } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { OverlayProvider } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';
import { useWindowSize } from 'usehooks-ts';
import { useLogOut } from '@/hooks/useLogOut';
import { Routes } from '@/constants/routes';
import { generateRoleBasePath } from '@/utils/generateRoleBasePath';
import { InvoiceToHostedPage } from '@/components/common/popups/InvoiceToHostedPage';
import { useIsMerchant } from '@/hooks/useIsMerchant';
import { useMemo } from 'react';
import useIsAUSResident from '@/hooks/useIsAUSResident';
import { Account, AccountProps } from '../Account';
import { Button } from '../buttons';
import { SelectList, SelectListItem } from '../form';
import { Icon, IconType } from '../Icon';
import { Container } from '../Container';
import { Logo } from '../Logo';
import { PopoverWithTrigger } from '../Popover';
import { NavigationTab } from '../Tabs';
import styles from './Navigation.module.css';
import { Popup } from '../Popup';

export interface NavigationDataProps {
  href?: string;
  title: string;
  chevron?: boolean;
  icon: IconType;
}

export interface DropdownDataProps {
  href: string;
  title: string;
  icon: IconType;
}

export interface NavigationProps {
  account: AccountProps;
  className?: string;
  navigationLinkList: NavigationDataProps[];
  dropdownLinkList: DropdownDataProps[];
  sellCryptoDropdownList: DropdownDataProps[];
}

export const NAVIGATION_TESTID = 'navigation';

export const Navigation = ({
  account,
  className,
  navigationLinkList,
  dropdownLinkList,
  sellCryptoDropdownList,
}: NavigationProps) => {
  const state = useOverlayTriggerState({});
  const { width } = useWindowSize();
  const router = useRouter();
  const logOut = useLogOut();
  const isMerchant = useIsMerchant();
  const { role, placeholder, src } = account;

  const { isAUSResident } = useIsAUSResident();

  // Filter out 'Buy Crypto' link if user is not from Australia
  const filteredNavigationLinkList = useMemo(
    () =>
      navigationLinkList.filter((item) => {
        if (!isAUSResident && item.title === 'Buy Crypto') {
          return false;
        }
        return true;
      }),
    [isAUSResident, navigationLinkList],
  );

  const renderExtraButton = () => {
    if (isMerchant) {
      return (
        <OverlayProvider>
          <Button
            onPress={state.open}
            variants="subtle"
            size={width > 1000 ? 'mediumSubtle' : 'smallSubtle'}
            icon="usd"
            className={styles.buttonLink}
          >
            Payments
          </Button>
          <Popup isOpen={state.isOpen} onClose={state.close} isDismissable={false} hasCloseIcon>
            <InvoiceToHostedPage />
          </Popup>
        </OverlayProvider>
      );
    }
    return null;
  };

  return (
    <>
      <nav data-testid={NAVIGATION_TESTID} className={styles.nav}>
        <Container className={styles.content}>
          <Link href={generateRoleBasePath(role, Routes.OVERVIEW)}>
            <Logo />
          </Link>
          {width > 1000 && (
            <ul className={classNames(styles.list, className)}>
              {map(filteredNavigationLinkList, ({ href, chevron, title }) => {
                return (
                  <li key={title} className={styles.item}>
                    {href ? (
                      <Link href={generateRoleBasePath(role, href)}>
                        <NavigationTab
                          label={title}
                          isActive={
                            router?.pathname === `${generateRoleBasePath(role, href)}/[[...slug]]` ||
                            router?.pathname === generateRoleBasePath(role, href)
                          }
                          chevron={chevron}
                        />
                      </Link>
                    ) : (
                      <PopoverWithTrigger
                        offset={2}
                        width={185}
                        className={styles.dropdownList}
                        renderTrigger={({ state, triggerProps, triggerRef }) => {
                          return (
                            <div className={styles.popupWrapper}>
                              <Button
                                {...triggerProps}
                                iconPosition="right"
                                variants="subtle"
                                size="mediumSubtle"
                                icon={state.isOpen ? 'chevronUp' : 'chevronDown'}
                                buttonRef={triggerRef}
                                onPress={state.open}
                                navigationTab
                                isActive={
                                  router?.pathname === `${generateRoleBasePath(role, Routes.SELL_CRYPTO)}/[[...slug]]`
                                }
                              >
                                {title}
                              </Button>
                              <span
                                className={clsx(styles.tabDash, {
                                  [styles.activeTabDash]:
                                    router?.pathname ===
                                    `${generateRoleBasePath(role, Routes.SELL_CRYPTO)}/[[...slug]]`,
                                })}
                              />
                            </div>
                          );
                        }}
                      >
                        <SelectList>
                          {sellCryptoDropdownList.map((item) => {
                            return (
                              <SelectListItem key={item.title}>
                                <Link className={styles.selectListLink} href={generateRoleBasePath(role, item.href)}>
                                  <Icon name={item.icon} size={18} className={styles.dropdownIcon} />
                                  {item.title}
                                </Link>
                              </SelectListItem>
                            );
                          })}
                        </SelectList>
                      </PopoverWithTrigger>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
          <div className={styles.buttons}>
            {renderExtraButton()}
            <PopoverWithTrigger
              className={styles.accountPopover}
              renderTrigger={({ state, triggerProps, triggerRef }) => {
                return (
                  <Account
                    {...triggerProps}
                    role={role}
                    placeholder={placeholder}
                    src={src}
                    buttonRef={triggerRef}
                    onClick={state.open}
                  />
                );
              }}
            >
              <SelectList logout onLogoutPress={logOut}>
                {map(dropdownLinkList, ({ href, title, icon }) => {
                  const finalHref = generateRoleBasePath(role, href);
                  return (
                    <SelectListItem key={title}>
                      {href ? (
                        <Link className={styles.selectListLink} href={finalHref}>
                          <Icon
                            name={icon}
                            className={clsx(styles.selectListLinkIcon, {
                              [styles.downloadIcon]: icon === 'downloadApp',
                            })}
                          />
                          {title}
                        </Link>
                      ) : (
                        <div className={styles.downloadListItem}>
                          <Icon
                            name={icon}
                            className={clsx(styles.selectListLinkIcon, {
                              [styles.downloadIcon]: icon === 'downloadApp',
                            })}
                          />
                          {title}
                          <span className={styles.selectListLinkLabel}>SOON</span>
                        </div>
                      )}
                    </SelectListItem>
                  );
                })}
              </SelectList>
            </PopoverWithTrigger>
          </div>
        </Container>
      </nav>
      {width < 1000 && (
        <nav className={styles.navMobile}>
          <Container className={styles.content}>
            <ul className={styles.list}>
              {map(filteredNavigationLinkList, ({ href, title, icon }) => {
                const finalHref = generateRoleBasePath(role, String(href));
                return (
                  <li key={title} className={styles.item} onClick={() => router.push(finalHref)}>
                    <Link
                      href={finalHref}
                      className={clsx(styles.linkMobile, {
                        [styles.linkMobileActive]:
                          router?.pathname === `${generateRoleBasePath(role, String(href))}/[[...slug]]` ||
                          router?.pathname === generateRoleBasePath(role, String(href)),
                      })}
                    >
                      {icon && <Icon name={icon} size={width < 768 ? 28 : 46} className={styles.iconMobile} />}
                      <span className={styles.linkMobileLabel}>{title}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Container>
        </nav>
      )}
    </>
  );
};
